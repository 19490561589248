.background {
    position: fixed;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-image: url("/imperial_britain_map.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.app {
    position: fixed;
    left: 0px;
    right: 0px;
    margin: 100px;
    text-align: center;
}

.host-game-dialog {
    max-width: 150px;
    display: inline-block;
}

.error-container {
    min-height: 300px;
    text-align: center;
}

.error-image {
    padding-top: 20px;
    max-width: 250px;
    max-height: 250px;
    overflow: hidden;
    border-radius: 50%;
}

.debugger {
    width: 1200px;
}

.command-history {
    padding-top: 30px;
    float: left;
    max-width: 500px !important;
    overflow: hidden;
}

.react-json-view {
    padding-top: 30px;
    float: right;
    max-width: 600px;
}

.container {
    margin: 0 auto;

    max-width: 600px;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
}

/* ENTER TRANSITION */
/* Declare transition start properties*/
.slide-enter {
    transform: translateX(-100vw);
    opacity: 0;
    position: absolute;
}

/* Declare transition properties */
.slide-enter.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms linear 300ms;
}

/* EXIT TRANSITION */
.slide-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-exit.slide-exit-active {
    transform: translateX(100vw);
    opacity: 0;
    transition: all 300ms linear;
}
